import {useEffect, useState} from "react";
import {AxiosResponse} from "axios";
import {DateTime} from "luxon";
import api, {endpoints} from "../utils/api";
import Layout from "./Layout";
import "../styles/DashboardPage.scss";
import FileUpload from "./files/FileUpload";
import {truncateFileName} from "../utils/truncateFileName";
import {fileDTO, fileShareDTO, fileURLModel, sharedFileDTO} from "../models/fileModel";
import {getEmptyBlob} from "../utils/formDataUtils";
import FileContentViewer from "./files/FileContentViewer";

export type File = {
    name: string;
    createdDate: string;
};

type Chat = {
    name: string;
    lastMessage: string;
    updatedDate: Date;
};

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export default function DashboardPage() {
    const [lastFiles, setLastFiles] = useState<File[]>([]);
    const [lastSharedFiles, setLastSharedFiles] = useState<File[]>([]);
    const [messages, setMessages] = useState<Chat[]>([]);


    //Get img blob after Search for FileModal (preview)
    const [blobURL, setBlobURL] = useState<fileURLModel>(getEmptyBlob())
    const [isOpenModal, setIsOpenModal] = useState(!!blobURL.fileURL)

    const getFileBlobHandler = async (fileId: string) => {
        // Set loading state first
        const loadingBlob = { fileURL: '', type: 'loading', blob: null };
        setBlobURL(loadingBlob);
        setIsOpenModal(true);
        
        try {
            const response = await api.get(endpoints.files + `/preview/${fileId}`, {responseType: "blob"})
            const responseFileBlob: Blob = response.data
            const fileURL = URL.createObjectURL(responseFileBlob)
            setBlobURL({fileURL: fileURL, type: responseFileBlob.type, blob: responseFileBlob})
        } catch (error) {
            console.error('Error fetching file preview:', error);
            // Close modal if there was an error
            setIsOpenModal(false);
        }
    }

    const recordsPerPage = 5;

    const onHideModalCallback = () => {
        setIsOpenModal(false)
        setTimeout(() => setBlobURL(getEmptyBlob()), 500)
    }

    useEffect(() => {
        loadData();
    }, []);

    function renderFilesList(array: any) {
        return array.map((file: fileDTO, index: number) => (
            <li style={{cursor: 'pointer'}} key={index} onClick={() => getFileBlobHandler(file.id)}>
                {truncateFileName(file.name, 50)}

                <p>
                    {DateTime.fromISO(file.createdDate, {zone: "utc"}).setZone(userTimeZone).toLocaleString(DateTime.DATETIME_MED)}
                </p>
            </li>
        ));
    }

    function renderSharedFilesList(array: any) {
        return array.map((file: sharedFileDTO, index: number) => (
            <li style={{cursor: 'pointer'}} key={index} onClick={() => getFileBlobHandler(file.fileId)}>
                {truncateFileName(file.name, 50)}

                <p>
                    {DateTime.fromISO(file.createdDate, {zone: "utc"}).setZone(userTimeZone).toLocaleString(DateTime.DATETIME_MED)}
                </p>
            </li>
        ));
    }

    const params = {
        page: 1,
        recordsPerPage: recordsPerPage,
        sortBy: "CreatedDate",
        sortOrder: "dsc",
    };

    function loadData() {
        api.get(endpoints.files, {
            params,
        })
            .then((response: AxiosResponse<File[]>) => {
                setLastFiles(response?.data?.slice(0, recordsPerPage));
            });

        api.get(`${endpoints.files}/shared`, {
            params,
        })
            .then((response: AxiosResponse<File[]>) => {
                setLastSharedFiles(response?.data.slice(0, recordsPerPage));
            });
        api.get(`${endpoints.chats}/chats`)
            .then((response: AxiosResponse<Chat[]>) => {
                const data = response.data.map((item) => ({
                    ...item,
                    createdDate: new Date(item.updatedDate),
                }));

                data.sort((a: any, b: any) => b.updatedDate - a.updatedDate);
                setMessages(data.slice(0, recordsPerPage));
            });
    }

    return (
        <Layout>
            <div className="dashboard-container">
                <div>
                    <FileUpload setLastFiles={setLastFiles}/>
                </div>
                <div>
                    <h2>Last Files</h2>
                    <ul>{renderFilesList(lastFiles)}</ul>
                </div>
                <div>
                    <h2>Last Shared Files</h2>
                    <ul>{renderSharedFilesList(lastSharedFiles)}</ul>
                </div>
                <div>
                    <h2>Last Messages</h2>
                    <ul>
                        {messages.map((item, index) => {
                            return (
                                <li key={index}>
                                    {item?.name}
                                    <p className="message">{item?.lastMessage}</p>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            {isOpenModal &&
                <FileContentViewer
                    blobURL={blobURL}
                    onHide={onHideModalCallback}
                />
            }
        </Layout>
    );
}
