import React, { useEffect, useState } from 'react';
import ProfilePic from './elements/ProfilePic';
import logo from '../resources/MUB_Logo_V_Black.png';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import HeaderPopup from './HeaderPopup';
import { HeaderProps } from './Headers.props';
import FilesListItem from './files/FilesListItem';
import { useNavigate } from 'react-router-dom';

const Header = React.forwardRef<HTMLDivElement, HeaderProps>(
    (
        {
            user,
            items,
            activeItem,
            handleLogout,
            //Popup props
            openPopup,
            isPopupOpen,
            //Search props
            textSearch,
            setTextSearch,
            foundedFiles,
            isLoadingFiles,
            //getFileBlobHandler prop
            getFileBlobHandler,
        },
        ref
    ) => {
        const navigate = useNavigate()

        const [isFocusInput, setIsFocusInput] = useState(false);
        const [displayName, setDisplayName] = useState('');

        useEffect(() => {
            //close logic dropdown (just close + after preview) 
            const handledCloseDropdown = (e: any) => {
                const composed = e.composedPath();

                if (!composed.some((el: any) => el.dataset?.closeim || el.className === 'fade modal')) {

                    setIsFocusInput(false);
                    document.body.removeEventListener('click', handledCloseDropdown);
                }
            };

            document.body.addEventListener('click', handledCloseDropdown);
        }, [isFocusInput]);

        const inputKeyPressedHandler = (key: string) => {
            if (key === 'Enter') {
                navigate('/found_files')
            }
        }

        useEffect(() => {
            if (user) {
                const name =
                    user.firstName || user.lastName
                        ? `${user.firstName || ''} ${user.lastName || ''}`.trim()
                        : user.email;
                setDisplayName(name);
            }
        }, [user]);

        return (
            <header className="header">
                <div data-closeim className="input-wrapper">
                    <div className="input-container">
                        <input
                            onFocus={() => setIsFocusInput(true)}
                            value={textSearch}
                            onChange={setTextSearch}
                            type="text"
                            placeholder={`Search with MUB`}
                            onKeyDown={(event) => inputKeyPressedHandler(event.key)}
                        />
                        {isFocusInput && (
                            <div className={'input-options-wrapper'}>
                                {foundedFiles.length ? (
                                    foundedFiles?.map((imgItem) => {
                                        return (
                                            <FilesListItem
                                                key={imgItem.id}
                                                imgName={imgItem.name}
                                                imgSize={imgItem.size}
                                                imgId={imgItem.id}
                                                getFileBlobHandler={getFileBlobHandler}
                                            />
                                        );
                                    })
                                ) : (
                                    <span>{!isLoadingFiles ? 'No options' : 'Loading...'}</span>
                                )}
                            </div>
                        )}
                    </div>
                    <MagnifyingGlassIcon className="h-6 w-6 text-gray-500" style={{cursor: 'pointer'}}
                                         onClick={() => inputKeyPressedHandler('Enter')}/>
                </div>
                <div className="user-wrapper">
                    <img className="user__logo-mobile" src={logo} alt="logo"/>
                    <div className="user__content">
                        <div className="user__data-wrapper">
                            <div className="user__text">
                                <span title={displayName}>
                                    {displayName}
                                </span>
                            </div>
                        </div>
                        <div className='user__settings' ref={ref} onClick={openPopup}>
                            <svg fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                 className="menu-icon">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
                            </svg>
                        </div>
                        <div ref={ref}>
                            <ProfilePic show={false}/>
                        </div>
                    </div>
                </div>
                <HeaderPopup
                    isOpen={isPopupOpen}
                    handleLogout={handleLogout}
                    items={items}
                    activeItem={activeItem}
                />
            </header>
        );
    }
);
export default Header;
