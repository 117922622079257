import { Navigate, Route, Routes } from "react-router-dom"
import Error404 from "./Error404"
import Messenger from "./messages/Messenger"
import Authorized from "./auth/Authorized"
import LoginPage from "./LoginPage"
import RegisterPage from "./RegisterPage"
import Layout from "./Layout"
import SettingsPage from "./SettingsPage"
import FilesPage from "./FilesPage"
import SharedFilesPage from "./SharedFilesPage"
import DashboardPage from "./DashboardPage"
import { useContext, useEffect, useState } from "react";
import CloudsPage from "./CloudsPage";
import AuthenticationContext from "./auth/AuthenticationContext"
import PaymentSuccess from "./payments/PaymentSuccess"
import PaymentCancel from "./payments/PaymentCancel"


function AppRouter() {
    const { isSubscribed } = useContext(AuthenticationContext);
    return (
        <>
            <Authorized authorized={
                <Routes>
                    <Route path="settings" element={<SettingsPage />} />
                    <Route path="paymentsuccess" element={<PaymentSuccess />} />
                    <Route path="paymentcancel" element={<PaymentCancel />} />

                    {isSubscribed ? (
                        <>
                            <Route path="/" element={<DashboardPage />} />

                            <Route path="files" element={<FilesPage />} />
                            <Route path="found_files" element={<FilesPage />} />
                            <Route path="messages" element={<Layout children={<Messenger />} />} />
                            <Route path="domains" element={<Layout />} />
                            <Route path="shared-files" element={<SharedFilesPage />} />
                            <Route path="clouds" element={<CloudsPage />} />
                        </>
                    ) : (
                        <Route path="*" element={<Navigate to="/settings" replace />} />
                    )}
                    <Route path="*" element={<Error404 />} />
                </Routes>}
                notAuthorized={
                    <Routes>
                        <Route path="*" element={<LoginPage />} />
                        <Route path="login" element={<LoginPage />} />
                        <Route path="register" element={<RegisterPage />} />
                    </Routes>
                } />
        </>
    )
}

export default AppRouter