import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import api, { endpoints } from "../../utils/api";

interface ProfilePicProps {
    show: boolean;
    openPopup?: () => void
}

const ProfilePic: React.FC<ProfilePicProps> = ({show, openPopup}) => {
    const defaultPic = require("../../resources/blank-profile-pic.png");
    const [picture, setPicture] = useState<string>("");
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [editModal, setEditModal] = useState<{
        showIt: boolean;
        changed: boolean;
    }>({
        showIt: false,
        changed: false,
    });

    // load pic on mount if available
    useEffect(() => {
        (async () => {
            try {
                const response = await api.get(endpoints.files + "/user-picture", {
                    responseType: "blob",
                });

                if (response.status === 200) {
                    const newPic = response.data;
                    const imageURL = URL.createObjectURL(newPic);
                    setPicture(imageURL);
                } else if (response.status === 204) {
                    // No content, set default picture
                    setPicture(defaultPic);
                }
            } catch (error) {
                // Handle other errors (e.g., network issues)
                console.error("Error fetching profile picture:", error);
                setPicture(defaultPic);
            }
        })();
    }, []);

    // show/hide editModal
    const editProfilePic = () => {
        setEditModal((prev) => ({...editModal, showIt: !prev.showIt}));
    };

    const removePicture = async () => {
        try {
            await api.delete(endpoints.files + "/user-picture");
            setPicture(defaultPic);
        } catch (error) {
            console.error("I think it's stuck: ", error);
        }
    };

    const handleSubmit = async () => {
        if (editModal.changed) {
            try {
                await api.post(
                    endpoints.files + "/user-picture",
                    {data: selectedFile},
                    {headers: {"Content-Type": "multipart/form-data"}}
                );

                // render new pic
                const tempBlob = new Blob([selectedFile!], {
                    type: selectedFile!.type,
                });
                const newImageURL = URL.createObjectURL(tempBlob);
                setPicture(newImageURL);

                // close modal
                setEditModal({...editModal, showIt: false});
            } catch (error) {
                console.error("The pic was too ugly to upload: ", error);
            }
        } else {
            alert("There has not been a new picture provided!");
        }
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const inFile = e.target.files;
        if (inFile && inFile.length > 0) {
            setSelectedFile(inFile[0]);
        }

        // unlocks submit button
        setEditModal({...editModal, changed: true});
    };

    return (
        <>
            <div id='profileP-container'>
                <div>
                    <img src={picture} alt='...'/>
                </div>
                {show ? (
                    <div id='editPic' onClick={() => editProfilePic()}>
                        <i className='bi bi-image-fill'></i>
                    </div>
                ) : null}
                {editModal.showIt ? (
                    <div id='edit-modal'>
                        <input
                            type='file'
                            accept='image/*'
                            onChange={(e) => handleImageChange(e)}
                            id='pp-file-input'
                        />
                        <Button
                            id='new-pic-submit'
                            variant='primary'
                            onClick={handleSubmit}
                            disabled={selectedFile === null}
                        >
                            Submit
                        </Button>
                        <Button
                            id='remove-pic'
                            variant='danger'
                            onClick={() => removePicture()}
                        >
                            Remove Picture
                        </Button>
                    </div>
                ) : null}
            </div>
        </>
    );
}

export default ProfilePic