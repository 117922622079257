import React, { useState, useEffect } from 'react';
import appstoreLogo from '../resources/appstoreLogo.png';
import playstoreLogo from '../resources/playstoreLogo.png';
import '../styles/AppPromotionBanner.scss';

const AppPromotionBanner = () => {
  const [isVisible, setIsVisible] = useState(true);
  
  // Time interval in milliseconds (1 minute = 60000 milliseconds)
  const REAPPEAR_INTERVAL = 15000;
  
  // Check if the banner should be shown based on time
  useEffect(() => {
    const checkBannerVisibility = () => {
      const dismissedTimestamp = localStorage.getItem('app_banner_dismissed_time');
      
      if (dismissedTimestamp) {
        const dismissedTime = parseInt(dismissedTimestamp, 10);
        const currentTime = Date.now();
        
        // If the elapsed time is less than REAPPEAR_INTERVAL, keep banner hidden
        if (currentTime - dismissedTime < REAPPEAR_INTERVAL) {
          setIsVisible(false);
        } else {
          // Time to show the banner again
          setIsVisible(true);
          // Remove the timestamp so it doesn't get rechecked until dismissed again
          localStorage.removeItem('app_banner_dismissed_time');
        }
      } else {
        // No timestamp found, show the banner
        setIsVisible(true);
      }
    };
    
    // Check immediately on load
    checkBannerVisibility();
    
    // Set interval to check regularly
    const intervalId = setInterval(checkBannerVisibility, 10000); // Check every 10 seconds
    
    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  
  const handleClose = () => {
    setIsVisible(false);
    // Store current timestamp when dismissed
    localStorage.setItem('app_banner_dismissed_time', Date.now().toString());
  };
  
  // Only render if it should be visible (controlled by CSS for mobile only)
  if (!isVisible) return null;
  
  return (
    <div className="app-promotion-overlay">
      <div className="app-promotion-content">
        <button className="close-button" onClick={handleClose}>×</button>
        <h2>Get the MyUnicBrain App</h2>
        <p>For the best experience, download our mobile app now!</p>
        <div className="app-store-links">
          <a href="https://apps.apple.com/ch/app/myunicbrain/id6689514181" target="_blank" rel="noopener noreferrer">
            <img src={appstoreLogo} alt="Download on the App Store" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.arssoso.mub_app" target="_blank" rel="noopener noreferrer">
            <img src={playstoreLogo} alt="Download on Google Play" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default AppPromotionBanner;