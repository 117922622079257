import React, { Dispatch, SetStateAction } from "react";
import { User } from "../../models/auth.models";
import { fileDTO } from "../../models/fileModel";
import { getEmptyBlob } from "../../utils/formDataUtils";

const AuthenticationContext = React.createContext<{

    isLoggedIn: boolean;
    setIsLoggedIn: (isLoggedIn: boolean) => void;

    user: User | null | undefined;
    setUser: (userData: User | null | undefined) => void;

    isSubscribed: boolean;
    setIsSubscribed: (status: boolean) => void;
    
    meRef: React.MutableRefObject<string>;
    
    foundedFiles: fileDTO[]
    setFoundedFiles: Dispatch<SetStateAction<fileDTO[]>>

    blobURLState: {fileURL: string, type: string, blob: Blob | null}
    setBlobURLState: Dispatch<React.SetStateAction<{
        fileURL: string;
        type: string;
        blob: Blob | null;
    }>>
    
    textSearch: string,
    setTextSearch: Dispatch<SetStateAction<string>>

    success: boolean;
    setSuccess: Dispatch<SetStateAction<boolean>>;
    message: string;
    setMessage: Dispatch<SetStateAction<string>>;
    mode: string;
    setMode: Dispatch<SetStateAction<string>>;
    duration: number;
    setDuration: Dispatch<SetStateAction<number>>;
}>({
    isLoggedIn: false,
    setIsLoggedIn: () => { },
    user: null,
    setUser: () => { },
    isSubscribed: false,
    setIsSubscribed: () => { },
    meRef: { current: "" },
    foundedFiles: [], setFoundedFiles: () => { },
    blobURLState: getEmptyBlob(), setBlobURLState: () => { },
    textSearch: '', setTextSearch: () => { },
    success: false, setSuccess: () => { },
    message: "", setMessage: () => { },
    mode: "", setMode: () => { },
    duration: 0, setDuration: () => { },
})

export default AuthenticationContext