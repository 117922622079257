import Register from './auth/Register';
import logoHBlack from '../resources/MUB_Logo_H_Black.png';
import logoVWhite from '../resources/MUB_Logo_V_White.png';
import appstoreLogo from '../resources/appstoreLogo.png';
import playstoreLogo from '../resources/playstoreLogo.png';
import AppPromotionBanner from './AppPromotionBanner';

import '../styles/LoginPage.scss';

export default function RegisterPage() {
    return (
        <div className="auth-wrapper auth-login-wrapper">
            <AppPromotionBanner />
            <div className="auth-page">
                {/* Left side - App preview */}
                <div className="auth-preview">
                    <div className="auth-preview__logo">
                        <img src={logoVWhite} alt="MyUnicBrain Logo"/>
                    </div>
                    <div className="auth-preview__content">
                        <p>
                            MyUnicBrain is the platform that intelligently stores all your knowledge and makes it
                            available to you at any time. Using the latest AI technology, MyUnicBrain supports the
                            conversion of your implicit knowledge into explicit knowledge without you having to manually
                            create folders or hierarchical structures.
                        </p>
                        <br/>
                        <p className="company-info">
                            MyUnicBrain AG Jöchlerweg 4, 6340 Baar
                        </p>
                    </div>
                </div>

                {/* Right side - Register form */}
                <div className="auth-form">
                    <div className="auth-form__container">
                        <div className="auth-form__logo">
                            <img src={logoHBlack} alt="MyUnicBrain Logo"/>
                        </div>

                        <Register/>

                        <div className="alternatives-container">
                            <span onClick={() => window.location.href = "/login"}>
                                Login
                            </span>
                        </div>

                        <div className="download-buttons-container">
                            <a href="https://apps.apple.com/ch/app/myunicbrain/id6689514181" target="_blank"
                               rel="noopener noreferrer">
                                <img src={appstoreLogo} alt="Download on the App Store"/>
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.arssoso.mub_app" target="_blank"
                               rel="noopener noreferrer">
                                <img src={playstoreLogo} alt="Download on Google Play"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='auth-form-footer'>
                <a href='https://myunicbrain.com/imprint/' target="_blank" rel="noopener noreferrer">Imprint</a>
                <a href='https://myunicbrain.com/privacy-policy/' target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            </div>
            
            {/* The original footer is hidden via CSS */}
            <div className='footer'>
                <a href='https://myunicbrain.com/imprint/' target="_blank" rel="noopener noreferrer">Imprint</a>
                <a href='https://myunicbrain.com/privacy-policy/' target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            </div>
        </div>
    )
}