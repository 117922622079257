import { userCredentials } from "../../models/auth.models";
import { useState } from "react";
import AuthForm from "./AuthForm";
import DisplayErrors from "../elements/DisplayErrors";
import { useNavigate } from "react-router-dom";
import api, { endpoints } from "../../utils/api";
import useFeedback from "../elements/customHooks/useFeedback";
import axios from 'axios';

export default function Register() {
    const [errors, setErrors] = useState<string[]>([]);
    const navigate = useNavigate();
    const { done } = useFeedback();

    async function register(credentials: userCredentials) {
        try {
            setErrors([]);
            const response = await api.post(endpoints.accounts + '/register', credentials);

            if (response.status === 200) {
                done("Registration completed", "success", 3000);
                navigate('/login');
            }
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                setErrors([error.message || "An error occurred. Please try again."]);
            } else {
                setErrors(["An unexpected error occurred. Please try again."]);
            }
        }
    }

    return (
        <>
            <div id="register">
                <AuthForm
                    model={{ email: '', password: '', acceptTerms: false }}
                    isRegister={true}
                    onSubmit={async values => await register(values)}
                />
                <DisplayErrors errors={errors} />
            </div>
        </>
    );
}
