import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import Layout from "../Layout";
import useFeedback from "../elements/customHooks/useFeedback";

export default function PaymentSuccess() {
    const navigate = useNavigate();
    const { done } = useFeedback();

    useEffect(() => {
        done("Payment successful! Your subscription is now active.", "success", 5000);
    }, []);

    return (
        <Layout>
            <Container className="text-center mt-5">
                <h2>🎉 Payment Successful!</h2>
                <p>Thank you for your subscription. You can now access all features.</p>
                
                <Button variant="primary" onClick={() => navigate("/")}>
                    Go to Dashboard
                </Button>
            </Container>
        </Layout>
    );
}
