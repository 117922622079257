// utils/api.ts
import axios from 'axios';
import ToastService from './toastService';

const apiBaseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
    baseURL: apiBaseURL,
    withCredentials: true,
});

api.interceptors.response.use(
    response => response,
    error => {
        // Extract the request URL
        const url = error.config && error.config.url;

        // List of endpoints to exclude from displaying the toast
        const noToastEndpoints = [
            '/accounts/login',
            '/accounts/register',
            '/accounts/user',
        ];

        // Check if the request URL matches any of the noToastEndpoints
        const isNoToastEndpoint = noToastEndpoints.some(endpoint => url && url.endsWith(endpoint));

        let errorMessage = "An unknown error occurred.";

        if (error.response && error.response.data) {
            let data = error.response.data;

            if (typeof data === 'string') {
                try {
                    data = JSON.parse(data);
                } catch (e) {
                    console.error('Failed to parse error response data as JSON:', e);
                    data = null;
                }
            }

            if (data && data.Message) {
                errorMessage = data.Message;
                console.error(`StatusCode: ${error.response.status} - Message: ${errorMessage}`);
            } else {
                console.log('Error response data does not contain Message:', data);
            }
        }

        // Attach the custom error message to the error object
        error.message = errorMessage;

        if (!isNoToastEndpoint) {
            // Display toast for endpoints not in the noToastEndpoints list
            ToastService.showToast(errorMessage, 'danger', 3000);
        }

        return Promise.reject(error);
    }
);

// Define Endpoints
export const endpoints = {
    accounts: `${apiBaseURL}/accounts`,
    files: `${apiBaseURL}/files`,
    tasks: `${apiBaseURL}/tasks`,
    chats: `${apiBaseURL}/chat`,
    chatHub: `${apiBaseURL}/chat-hub`,
    domains: `${apiBaseURL}/domains`,
    clouds: `${apiBaseURL}/clouds`,
    payment: `${apiBaseURL}/payment`,
};

export default api;
