import { userCredentials } from "../../models/auth.models";
import { useContext, useEffect, useState } from "react";
import AuthForm from "./AuthForm";
import DisplayErrors from "../elements/DisplayErrors";
import { useNavigate, useLocation } from "react-router-dom";
import AuthenticationContext from "./AuthenticationContext";
import api, { endpoints } from "../../utils/api";
import PasswordResetModal from "./PasswordResetModal";
import useFeedback from "../elements/customHooks/useFeedback";
import axios from 'axios';

export default function Login() {
    const [errors, setErrors] = useState<string[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const {isLoggedIn, setIsLoggedIn, setIsSubscribed, setUser} = useContext(AuthenticationContext);

    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    const [emailFromUrl, setEmailFromUrl] = useState<string | null>(null);
    const [tokenFromUrl, setTokenFromUrl] = useState<string | null>(null);
    const [needsEmailConfirmation, setNeedsEmailConfirmation] = useState(false);
    const [emailForResend, setEmailForResend] = useState('');
    const [resendInProgress, setResendInProgress] = useState(false);

    const {done} = useFeedback();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        const emailConfirmationStatus = searchParams.get('emailConfirmed');
        if (emailConfirmationStatus === 'true') {
            done("Email confirmed", "success", 3000);
        }

        const emailFromQuery = searchParams.get('email');
        const tokenFromQuery = searchParams.get('token');

        if (emailFromQuery && tokenFromQuery) {
            setEmailFromUrl(emailFromQuery);
            setTokenFromUrl(tokenFromQuery);
            setShowResetPasswordModal(true);
        }

    }, [location.search]);

    const handleCloseResetPasswordModal = () => {
        setShowResetPasswordModal(false);
        navigate('/login', {replace: true}); // Clean up URL parameters
    };

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/');
        }
    }, [isLoggedIn, navigate]);

    async function login(credentials: userCredentials) {
        try {
            setErrors([]);
            setNeedsEmailConfirmation(false);
            const response = await api.post(endpoints.accounts + '/login', credentials);
            if (response.status === 200) {
                setIsLoggedIn(true);

                // Fetch the user data after login
                try {
                    const userResponse = await api.get(endpoints.accounts + '/user');
                    setUser(userResponse.data);
                } catch (userError) {
                    console.error("Failed to fetch user data after login", userError);
                }

                // Fetch subscription status after login
                try {
                    const subscriptionResponse = await api.get(endpoints.payment + `/subscription`);
                    setIsSubscribed(subscriptionResponse.data.active);
                } catch (subError) {
                    console.error("Failed to fetch subscription status", subError);
                    setIsSubscribed(false);
                }

                navigate('/');
            }
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                const errorMessage = error.message || "An error occurred. Please try again.";

                // Check if this is the email confirmation error (both in English and German)
                if (errorMessage.includes("Please confirm your mail") ||
                    errorMessage.includes("confirm") ||
                    errorMessage.includes("bestätigen") ||
                    errorMessage.includes("Bestätigung")) {
                    setNeedsEmailConfirmation(true);
                    setEmailForResend(credentials.email);
                }

                setErrors([errorMessage]);
            } else {
                setErrors(["An unexpected error occurred. Please try again."]);
            }
        }
    }

    async function resendConfirmationEmail() {
        if (!emailForResend) return;

        setResendInProgress(true);
        try {
            // Send email as a query parameter
            await api.post(endpoints.accounts + '/resend-confirmation-email'
                + `?email=${encodeURIComponent(emailForResend)}`);
            // Hide the resend confirmation section after successful send
            setNeedsEmailConfirmation(false);
            // Clear any existing errors
            setErrors([]);
            done("Confirmation email has been resent. Please check your inbox.", "success", 5000);
        } catch (error: any) {
            done("Failed to resend confirmation email. Please try again later.", "danger", 5000);
        } finally {
            setResendInProgress(false);
        }
    }

    return (
        <>
            {showResetPasswordModal ?
                <PasswordResetModal
                    email={emailFromUrl || ''}
                    token={tokenFromUrl || ''}
                    handleClose={handleCloseResetPasswordModal}
                    show={showResetPasswordModal}/>
                :
                <div id="login">
                    <AuthForm
                        model={{email: '', password: ''}}
                        onSubmit={async values => await login(values)}/>
                    <DisplayErrors errors={errors}/>

                    {needsEmailConfirmation && (
                        <div className="resend-confirmation">
                            <p>Haven't received your confirmation email?</p>
                            <button
                                className="resend-button"
                                onClick={resendConfirmationEmail}
                                disabled={resendInProgress}
                            >
                                {resendInProgress ? 'Sending...' : 'Send confirmation email'}
                            </button>
                        </div>
                    )}
                </div>
            }
        </>
    );
}
