import logo from "../resources/MUB_Logo_V_Black.png";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { MenuItem } from "./Layout";
import React, { useContext } from "react";
import AuthenticationContext from "./auth/AuthenticationContext";

interface SideBarProps {
  items: MenuItem[];
  activeItem: MenuItem;
  handleLogout: () => {};
  newsCount: number
}

export default function SideBar({
  items,
  activeItem,
  handleLogout,
  newsCount,
}: SideBarProps) {

    const { setTextSearch, setFoundedFiles } = useContext(AuthenticationContext)
    
  return (
    <div className='sidebar'>
      <div className='sidebar__logo-wrapper'>
        <img className='sidebar__logo' src={logo} alt='logo' />
        <img className='sidebar__logo-no-text' src={logo} alt='logo' />
        <p className='sidebar__logo-desc'>{activeItem?.name}</p>
      </div>

      <ul>
        {items.map((i: any, index: number) => {
          const isActive = i.name === activeItem.name;
          const isMessages = i.name === 'Messages';
          const isFiles = i.name === 'Files';
          return (
            <li key={index} className={`${isActive && "active"}`}>
              <Link to={`${i.to}`} onClick={() => {
                if(isFiles) {
                    setTextSearch('')
                    setFoundedFiles([])
                }
              }}>
                {" "}
                {i?.icon}
                <span>{i.name}</span>
                {isMessages && newsCount > 0 && <span>{newsCount}</span>}
              </Link>
            </li>
          );
        })}
      </ul>

      {/* Sign Out moved to burger menu */}
    </div>
  );
}
