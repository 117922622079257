import React, { useEffect, useRef } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { pdfjs } from 'react-pdf';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import 'pdfjs-dist/build/pdf.worker.entry';
import '../../styles/FileContentViewer.scss';

pdfjs.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.min.js');

export interface FileContentViewerProps {
    blobURL: { fileURL: string, type: string, blob: Blob | null };
    onHide: () => void;
}

const FileContentViewer: React.FC<FileContentViewerProps> = ({ blobURL, onHide }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const modalRef = useRef<HTMLDivElement>(null);

    const isPDF = blobURL.type === 'application/pdf';
    const isImage = blobURL.type.startsWith('image/');
    const isText = blobURL.type === 'text/plain';
    const isMarkdown = blobURL.type === 'text/markdown';

    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onHide();
            }
        };

        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onHide();
            }
        };

        window.addEventListener('keydown', handleEsc);
        window.addEventListener('mousedown', handleClickOutside);
        return () => {
            window.removeEventListener('keydown', handleEsc);
            window.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onHide]);

    const renderFileContent = () => {
        // Show loading spinner if the file is loading
        if (blobURL.type === 'loading') {
            return (
                <div className="loading-spinner-container">
                    <div className="loading-spinner"></div>
                    <p>Loading file content...</p>
                </div>
            );
        } else if (isPDF) {
            return (
                <Worker workerUrl={pdfjs.GlobalWorkerOptions.workerSrc}>
                    <div className="pdf-viewer">
                        <Viewer fileUrl={blobURL.fileURL} plugins={[defaultLayoutPluginInstance]} />
                    </div>
                </Worker>
            );
        } else if (isImage) {
            return (
                <div className="image-viewer">
                    <img
                        className="image-content"
                        src={blobURL.fileURL}
                        alt="File content"
                    />
                </div>
            );
        } else if (isText || isMarkdown) {
            return (
                <iframe
                    title="file-content"
                    src={blobURL.fileURL}
                    style={{ width: '100%', height: '100%', overflow: 'auto', border: 'none' }}
                />
            );
        } else {
            return <div className="unsupported-file-type"><p>Unsupported file type</p></div>;
        }
    };

    return (
        <div className="custom-modal">
            <div className="custom-modal-content" ref={modalRef}>
                {renderFileContent()}
            </div>
            <button className="custom-modal-close" onClick={onHide}><span>×</span></button>
        </div>
    );
};

export default FileContentViewer;
