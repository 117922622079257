import * as Yup from 'yup';
import { Form, Formik, FormikHelpers } from "formik";
import FormTextInput from '../elements/FormTextInput';
import { userCredentials } from "../../models/auth.models";
import { confirmPasswordValidation, emailValidation, passwordValidation } from '../../validations';


export default function AuthForm(props: authFormProps) {

    let validationObject: any = {
        email: Yup.string().required('Email is required'),
        password: Yup.string().required("Password is required")
    };
    
    if (props.isRegister) {
        validationObject.email = emailValidation;
        validationObject.password = passwordValidation;
        validationObject.confirmPassword = confirmPasswordValidation;
        validationObject.acceptTerms = Yup.boolean()
            .required('You must accept the Privacy Policy')
            .oneOf([true], 'You must accept the Privacy Policy');
    }
    
    const validationSchema = Yup.object().shape(validationObject);    
    

    return (
        <Formik
            initialValues={props.model}
            onSubmit={props.onSubmit}
            validationSchema={validationSchema}>
            {formikProps => (
                <Form>
                    <FormTextInput name={'email'} displayName={'Email'} />
                    <FormTextInput name={'password'} displayName={'Password'} type="password" />
                    {props.isRegister ?
                        <>
                            <FormTextInput name={'confirmPassword'} displayName={'Confirm Password'} type="password" />
                            <div className="terms-checkbox-container">
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        id="acceptTerms"
                                        name="acceptTerms"
                                        className="form-check-input"
                                        onChange={formikProps.handleChange}
                                        checked={formikProps.values.acceptTerms}
                                    />
                                    <label htmlFor="acceptTerms" className="form-check-label">
                                        I accept the <a href="https://myunicbrain.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                                    </label>
                                </div>
                                {formikProps.errors.acceptTerms && formikProps.touched.acceptTerms && (
                                    <div className="error-message">{formikProps.errors.acceptTerms}</div>
                                )}
                            </div>
                        </> :
                        null
                    }
                    <div>
                        <button 
                            className="auth-button" 
                            type="submit" 
                            disabled={formikProps.isSubmitting}
                        >
                            {props.isRegister ? 'Register' : 'Login'}
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

interface authFormProps {
    model: userCredentials;
    isRegister?: boolean;
    onSubmit(values: userCredentials, actions: FormikHelpers<userCredentials>): void;
}