import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import Layout from "../Layout";
import useFeedback from "../elements/customHooks/useFeedback";

export default function PaymentCancel() {
    const navigate = useNavigate();
    const { error } = useFeedback();

    useEffect(() => {
        error("Payment was canceled. You can try again anytime.");
    }, []);

    return (
        <Layout>
            <Container className="text-center mt-5">
                <h2>⚠️ Payment Canceled</h2>
                <p>It looks like you canceled the payment. If this was a mistake, you can try again.</p>
                
                <Button variant="primary" onClick={() => navigate("/settings")}>
                    Try Again
                </Button>
            </Container>
        </Layout>
    );
}
