import { useRef } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import api, { endpoints } from "../../utils/api";
import useFeedback from "../elements/customHooks/useFeedback";
import * as Yup from 'yup';
import FormTextInput from "../elements/FormTextInput";
import { Formik } from "formik";

export default function RequestPasswordResetModel(props: PasswordResetPopupProps) {

    const { done } = useFeedback();

    // initiate mail request
    async function requestReset(mail: string) {
        try {
            const response = await api.put(`${endpoints.accounts}/password/request`, JSON.stringify(mail), {headers: {'Content-Type': 'application/json'}});
            if (response.status === 200) {
                done("Password reset request sent", "success", 3000)
                props.closePopup()
            }
        } catch (error) {
            done("Password reset request failed", "danger", 3000)
        }
    }

    const emailValidationSchema = Yup.object({
        email: Yup.string().required('Email is required').email('You have to insert a valid email'),
    });

    return (
        <Modal show={true} onHide={props.closePopup} centered>
            <Modal.Header closeButton>
                <Modal.Title>Reset Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{ email: "" }}
                    validationSchema={emailValidationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        requestReset(values.email).finally(() => setSubmitting(false));
                    }}
                >
                    {formikProps => (
                        <Form id="pwrp-container" onSubmit={formikProps.handleSubmit}>
                            <Row>
                                <Form.Group>
                                    <FormTextInput name="email" displayName="Email" />
                                </Form.Group>
                            </Row>
                            <Row className="mt-3">
                                <div className="d-flex justify-content-end">
                                    <Button variant="secondary" className="me-2" onClick={props.closePopup}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" variant="primary" id="pwrp-sendBtn">
                                        Reset Password
                                    </Button>
                                </div>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

interface PasswordResetPopupProps {
    closePopup(): void;
}